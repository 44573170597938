<template lang="pug">
  div
    div.tunes
      div.enforce-max-width
        p.center-loader(v-if="isBusy")
          b-spinner.align-middle.mx-2
          strong Loading...
        div.content-to-fade(v-bind:class="ready")
          div
            p Instrumental folk tunes - © Benjamin Schreiber
            .mb-0.mt-2#tune-search
              b-input-group(size="sm")
                b-form-input#filterInput(v-model="filter", type="search", placeholder="Type to Search")
                b-button.d-inline-block(:disabled="!filter", @click="filter = ''") Clear
          b-table.mt-3#tunes-table(:items="tuneList", :fields="fields", :busy="isBusy", caption-top, primary-key="name", :filter="filter", :filter-included-fields="filterOn")
            template(#cell(name)="data")
              span.tune-name {{ data.item.name }}
            template(#cell(meter)="data")
              span.tune-meter {{ data.item.meter }}
            template(#cell(key)="data")
              span.tune-key {{ data.item.key }}
            template(#cell(viewordownload)="data")
              .d-block.d-md-inline-block.mr-0.mr-md-3
                p.d-block.d-lg-inline-block.pr-0.pr-lg-2 Download:
                b-button-group(size="sm")
                  b-button(v-if="data.item.downloadpdf", :href="data.item.downloadpdf", variant="outline-info")
                    |PDF
                    b-icon-download.ml-1.d-none.d-sm-inline-block
                  b-button(v-if="data.item.downloadabc", :href="data.item.downloadabc", variant="outline-info")
                    |ABC
                    b-icon-download.ml-1.d-none.d-sm-inline-block
              .d-block.d-md-inline-block
                p.d-block.d-lg-inline-block.pr-0.pr-lg-2 View:
                b-button-group(size="sm")
                  b-button(v-if="data.item.viewpdf", :href="data.item.viewpdf", target="_blank", variant="outline-info")
                    |PDF
                    b-icon-eye.ml-1.d-none.d-sm-inline-block
                  b-button(v-if="data.item.viewabc", :href="data.item.viewabc", target="_blank", variant="outline-info")
                    |ABC
                    b-icon-eye.ml-1.d-none.d-sm-inline-block
</template>

<script>
export default {
  name: "Tunes",
  data() {
    return {
      isBusy: true,
      ready: "notready",
      tuneList: [],
      fields: [
        { key: "name", label: "Title", sortable: true },
        { key: "meter", label: "Meter", sortable: true },
        { key: "key", label: "Key", sortable: true },
        { key: "viewordownload", label: "Links" }
      ],
      filter: null,
      filterOn: ["name", "meter", "key"]
    };
  },
  created() {
    fetch(process.env.VUE_APP_TUNESHEET, {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        var tuneData = [];
        var appendToPrevious = false;
        var previousEntry = null;
        var tuneInfo = [];
        var meter = "";

        data.values.forEach(function(value) {
          tuneInfo = value[2].split("+");

          appendToPrevious =
            previousEntry && previousEntry.id == tuneInfo[0] ? true : false;

          if (appendToPrevious) {
            if (value[3] === "abc") {
              previousEntry.downloadabc = value[5];
              previousEntry.viewabc = value[4];
            } else if (value[3] === "pdf") {
              previousEntry.downloadpdf = value[5];
              previousEntry.viewpdf = value[4];
            }
          } else {
            switch (tuneInfo[1]) {
              case "waltz":
                meter = "3/4";
                break;
              case "reel":
                meter = "4/4";
                break;
              case "jig":
                meter = "6/8";
            }

            var entry = {
              id: tuneInfo[0],
              name: tuneInfo[0].replace(/_/g, " ").replace(/-/g, "'"),
              meter: meter,
              key: tuneInfo[2],
              viewabc:
                value[3] === "abc" ? value[4] : "",
              downloadabc:
                value[3] === "abc"
                  ? value[5]
                  : "",
              viewpdf:
                value[3] === "pdf" ? value[4] : "",
              downloadpdf:
                value[3] === "pdf" ? value[5] : ""
            };
            tuneData.push(entry);
          }

          previousEntry = entry;
        });
        this.tuneList = tuneData;
        this.isBusy = false;
        this.ready = "ready";
      });
  }
};
</script>

<style scoped lang="less">
.content-to-fade {
  opacity: 0;

  -webkit-transition: opacity 2.8s;
  -moz-transition: opacity 2.8s;
  transition: opacity 2.8s;

  &.ready {
    opacity: 1;
  }
}
</style>
